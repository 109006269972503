import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { ApplicationLayout } from 'shared/components/ApplicationLayout';
import { UserRole } from 'shared/models';
import { useAccounts } from 'shared/store/modules/accounts/hooks';
import { selectRole, selectIsStreamlinedProspect } from 'shared/store/modules/auth/selectors';

const SessionComponent = withAuthenticationRequired(() => {
  const userRole = useSelector(selectRole);
  const isStreamlinedProspect = useSelector(selectIsStreamlinedProspect);
  const { activeAccountId } = useAccounts();

  if (userRole === UserRole.Prospect) {
    if (isStreamlinedProspect === true) return <Redirect to="/signup/next" />;
    return <Redirect to="/residential/next" />;
  }

  if (!activeAccountId && userRole !== UserRole.Admin) {
    return <Redirect to="/accounts" />;
  }

  return <ApplicationLayout />;
}, { returnTo: window.location.pathname });


export const Session = () => {
  const { error } = useAuth0();

  if (error) {
    console.error(error);

    return <Redirect to={`/login?error=${error.message}`} />
  }

  return <SessionComponent />
}
