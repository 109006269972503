import styled from 'styled-components';
import sctheme from 'app/styles/sctheme';

type LayoutProps = {
  image: string;
};

export const Layout = styled.div<LayoutProps>`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: ${sctheme.grays.$900};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
`;

export const AuthContainer = styled.div`
  margin: 16px auto 0;
  max-width: 500px;
  background-color: ${sctheme.white};
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 50px 50px 27px;
`;

export const BannerContainer = styled.div`
  display: flex;
  padding: 16px;
  margin: 75px auto 0;
  max-width: 500px;
  background-color: ${sctheme.system.infoLight};
  border: 1px solid ${sctheme.system.info};
  border-radius: 4px;
  color: ${sctheme.black};

  p {
    color: ${sctheme.black};
    margin: 0;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    color: ${sctheme.blues.secondary}; //#0072B7
  }
`;
export const ErrorContainer = styled.div`
  display: flex;
  margin: 20px auto 0;
  max-width: 500px;
`;
