import { useEffect } from 'react';
import Loading from 'shared/components/Loading';
import { useAuth0Session } from 'shared/store/modules/auth/hooks';

export const LoginDispatch = () => {
  const { automaticDispatchToRoute, isAuthenticated } = useAuth0Session();

  useEffect(() => {
    if (isAuthenticated) {
      automaticDispatchToRoute()
    }
  }, [isAuthenticated, automaticDispatchToRoute])

  return (
    <Loading />
  )
}
