import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';

import { auth0Login } from 'shared/store/modules/auth/effects';
import { fetchAccounts } from 'shared/store/modules/accounts/effects';
import { fetchProspect, checkInquiryCapacity } from 'shared/store/modules/prospects/effects';
import { setActiveAccountId } from 'shared/store/modules/accounts/actions';
import { getProviderHostname } from 'shared/store/modules/provider/selectors';

import { useAuth0 } from "@auth0/auth0-react";

export function useAuth0Session() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const hostname = useSelector(getProviderHostname);
  const [error, setError] = useState(null);
  const { user, getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

  const isAdmin = useCallback(() => {
    if (!isAuthenticated) return false;
    return user && user['http://schemas.suncentral.net/claims/roles']?.join(' ').toLowerCase().includes('admin');
  }, [isAuthenticated, user])

  const getRole = useCallback(() => {
    if (!isAuthenticated) return null
    return user['http://schemas.suncentral.net/claims/roles'][0];
  }, [isAuthenticated, user])

  const renewSession = useCallback((sessionCallback) => {
    if (isAuthenticated) {
      try {
        getAccessTokenSilently({ detailedResponse: true }).then((response) => {
          const expires = new Date();
          expires.setSeconds(expires.getSeconds() + response.expires_in);

          dispatch(auth0Login({
            ...response,
            role: getRole(),
            hostname: hostname,
            expires_in: response.expires_in,
            expires: expires
          }, user.sub));

          sessionCallback(response);
        });
      } catch (error) {
        setError(error)
        console.error('Error caught in useAuth0Session', error);
      }
    }
  }, [getAccessTokenSilently, dispatch, user, hostname, isAuthenticated, getRole]);

  const automaticDispatchToRoute = useCallback(() => {
    renewSession(async () => {
      let redirectTo = '/';

      if (!isAdmin()) {
        const accounts = await dispatch(fetchAccounts());

        if (accounts.length === 1) {
          dispatch(setActiveAccountId(accounts[0].id));
          if (
            accounts[0].isStreamlinedProspect &&
            accounts[0].nextStepToComplete &&
            accounts[0].nextStepToComplete === 600
          ) {
            const prospect = await dispatch(fetchProspect(accounts[0].id));

            if (prospect) {
              const { associatedUtilityId, stateId } = prospect.payload;
              await dispatch(checkInquiryCapacity(null, associatedUtilityId, stateId))
                .then((data) => {
                  if (data?.payload?.data) {
                    const { programsAndCapacityAvailable } = data.payload.data;
                    if (!programsAndCapacityAvailable) {
                      redirectTo = '/signup/review?addToWaitlist=true';
                    }
                  }
                })
                .catch(error => {
                  console.error('error ==>', error);
                  setError(error);
                });
            }
          }
        } else {
          redirectTo = '/accounts';
        }
      }

      history.push(redirectTo);
    })
  }, [dispatch, isAdmin, history, renewSession]);

  return { automaticDispatchToRoute, renewSession, user, error, isLoading, isAuthenticated };
}
