import React from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

import styles from './styles';

export function LoginForm() {
  const {loginWithRedirect, logout, isAuthenticated} = useAuth0()

  return (
    <>
      <div css={styles}>
        <Button block color="primary" size="lg" onClick={() => loginWithRedirect()}>Continue to Login</Button>
        {isAuthenticated && (
          <Button className="mt-2" block color="primary" size="lg" onClick={() => logout()}>Logout</Button>
        )}
      </div>
    </>
  );
};
