import { selectAccountId } from 'shared/store/modules/accounts/selectors';
import { logout } from 'shared/store/modules/auth/actions';
import { ProspectType } from 'shared/models/PropsectType';
import { renewSession } from 'shared/store/modules/auth/effects';
import {
  checkInquiryCapacityRequest,
  checkInquiryCapacityResponse,
  checkReferralCodeRequest,
  checkReferralCodeResponse,
  createInquiryRequest,
  createInquiryResponse,
  createProspectWithPremise,
  creditCheckRequest,
  creditCheckResponse,
  error,
  fetchClaimTicketRequest,
  fetchClaimTicketResponse,
  fetchCodeDetailsRequest,
  fetchCodeDetailsResponse,
  fetchContractDetailsRequest,
  fetchContractDetailsResponse,
  fetchContractDetailsWithProspectIdRequest,
  fetchContractDetailsWithProspectIdResponse,
  fetchContractRequest,
  fetchContractResponse,
  fetchCreditCheckActionRequest,
  fetchCreditCheckActionResponse,
  fetchInquiryInfoRequest,
  fetchInquiryInfoResponse,
  fetchProspectDocumentsRequest,
  fetchProspectDocumentsResponse,
  fetchProspectRequest,
  fetchProspectResponse,
  fetchRequest,
  fetchSavingsRequest,
  fetchSavingsResponse,
  fetchUtilityMeters,
  getLegalTemplate,
  getOffers,
  getRateTypes,
  getUtilAuthUrlResponse,
  invalidateContractRequest,
  patchInquiryRequest,
  patchInquiryResponse,
  patchProspectRequest,
  patchProspectResponse,
  resendInviteRequest,
  resendInviteResponse,
  resetZipCodeRequest,
  selectMetersForActivation,
  setPasswordRequest,
  setPasswordResponse,
  setUtilAuthRequest,
  setUtilAuthResponse,
  sign,
  uploadProspectDocumentRequest,
  uploadProspectDocumentResponse,
  validateContractRequest,
  validateContractResponse,
  validateTokenRequest,
  validateTokenResponse,
  signupRequest,
  signupResponse
} from 'shared/store/modules/prospects/actions';
import toast from 'shared/utilities/toast';

export const fetchProspect = (prospectId, history = null) => async (dispatch, _, { api }) => {
  dispatch(fetchProspectRequest());
  try {
    const { data } = await api.prospect.fetchProspect(prospectId);
    return dispatch(fetchProspectResponse(data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      await dispatch(fetchProspectResponse(err));
      if (history) return history.replace('/logout');
      return await dispatch(logout());
    } else {
      return dispatch(fetchProspectResponse(err));
    }
  }
};

export const patchProspect = model => async (dispatch, _getState, { api }) => {
  dispatch(patchProspectRequest());
  try {
    let data = await api.prospect.patchProspect(model);
    return dispatch(patchProspectResponse(data.data));
  } catch (err) {
    return dispatch(patchProspectResponse(err));
  }
};

export const checkInquiryCapacity = (stateName, id, stateId) => async (dispatch, _getState, { api }) => {
  dispatch(checkInquiryCapacityRequest());

  try {
    let data = await api.prospect.checkCapacity(stateName, id, stateId);
    return dispatch(checkInquiryCapacityResponse(data));
  } catch (err) {
    return dispatch(checkInquiryCapacityResponse(err));
  }
};

export const patchInquiry = model => async (dispatch, _getState, { api }) => {
  dispatch(patchInquiryRequest());
  try {
    let data;
    if (model.prospectType) {
      data = await api.prospect.updateProspect(model);
    } else {
      model.zipCode = _getState().prospects.zipCode;
      model.associatedUtilityId = _getState().prospects.utilityId;
      model.countyId = _getState().prospects.countyId;
      model.prospectType = ProspectType.Residential;
      data = await api.prospect.updateProspect(model);
    }
    return dispatch(patchInquiryResponse(data.data));
  } catch (err) {
    return dispatch(patchInquiryResponse(err));
  }
};

export const resendInvite = prospectId => async (dispatch, _getState, { api }) => {
  dispatch(resendInviteRequest());
  try {
    let data = await api.prospect.resendInvite(prospectId);
    return dispatch(resendInviteResponse(data.data));
  } catch (err) {
    return dispatch(resendInviteResponse(err));
  }
};

export const validateToken = (token, encodedEmail) => async (dispatch, _getState, { api }) => {
  const email = decodeURIComponent(encodedEmail.replace('%20', '+'));
  dispatch(validateTokenRequest(token));
  try {
    let { data } = await api.prospect.validateToken(token, email);
    return dispatch(validateTokenResponse({ ...data, token, email }));
  } catch (err) {
    return dispatch(validateTokenResponse(err));
  }
};

export const signup = (token, utility) => async (dispatch, _getState, { api }) => {
  dispatch(signupRequest());
  try {
    let { data } = await api.prospect.signup(token);
    return dispatch(signupResponse({ ...data, token, utility }));
  } catch (err) {
    return dispatch(signupResponse(err));
  }
};

export const setPassword = (token, email, password) => async (dispatch, _getState, { api }) => {
  dispatch(setPasswordRequest());
  try {
    let data = await api.prospect.setPassword(token, email, password);
    return dispatch(setPasswordResponse(data));
  } catch (err) {
    return dispatch(setPasswordResponse(err));
  }
};

export const getUtilAuthURL = prospectId => async (dispatch, _getState, { api }) => {
  dispatch(setUtilAuthRequest());

  try {
    const { data } = await api.prospect.getAuthorizationUrl(prospectId);
    return dispatch(getUtilAuthUrlResponse(data));
  } catch (err) {
    return dispatch(error(err));
  }
};

export const setUtilAuthComplete = (prospectId, referral) => async (dispatch, _getState, { api }) => {
  dispatch(setUtilAuthRequest());

  try {
    const { data } = await api.prospect.setUtilAuthComplete(prospectId, referral);
    return dispatch(setUtilAuthResponse({ prospectId, ...data }));
  } catch (err) {
    return dispatch(error(err));
  }
};

export const creditCheck = values => async (dispatch, _getState, { api }) => {
  dispatch(creditCheckRequest());
  try {
    const { data } = await api.prospect.creditCheck(values);
    return dispatch(creditCheckResponse(data));
  } catch (err) {
    return dispatch(error(err));
  }
};

export const resetZipCode = () => async dispatch => {
  dispatch(resetZipCodeRequest());
};

export const fetchSavings = (zipCode, powerCost, isAnnual = false) => async (dispatch, _getState, { api }) => {
  dispatch(fetchSavingsRequest({ isAnnual, zipCode, powerCost }));
  try {
    let data = await api.prospect.fetchSavings(zipCode, powerCost, isAnnual);
    return dispatch(fetchSavingsResponse(data.data));
  } catch (err) {
    // toast.error(err);
    return dispatch(fetchSavingsResponse(err));
  }
};

export const fetchInquiryInfo = zipCode => async (dispatch, _getState, { api }) => {
  dispatch(fetchInquiryInfoRequest(zipCode));
  try {
    let data = await api.prospect.fetchInquiryInfo(zipCode);
    return dispatch(fetchInquiryInfoResponse(data.data));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchInquiryInfoResponse(err));
  }
};

export const createInquiry = (zipCode, utilityId, countyId, referralCode) => async (dispatch, _getState, { api }) => {
  dispatch(
    createInquiryRequest({
      zipCode,
      utilityId,
      countyId,
      referralCode
    })
  );
  try {
    let data = await api.prospect.createProspect(zipCode, utilityId);
    return dispatch(createInquiryResponse(data.data));
  } catch (err) {
    toast.error(err);
    return dispatch(createInquiryResponse(err));
  }
};

export const fetchMeters = () => async (dispatch, getState, { api }) => {
  dispatch(fetchRequest());

  const prospectId = selectAccountId(getState());

  try {
    let data = await api.prospect.fetchUtilityMeters(prospectId);
    return dispatch(fetchUtilityMeters(data));
  } catch (err) {
    toast.error(err);
    return dispatch(error(err));
  }
};

export const fetchRateTypes = prospectId => async (dispatch, getState, { api }) => {
  dispatch(fetchRequest());

  if (!prospectId) {
    prospectId = selectAccountId(getState());
  }

  try {
    let data = await api.prospect.fetchRateTypes(prospectId);
    return dispatch(getRateTypes(data));
  } catch (err) {
    toast.error(err);
    return dispatch(error(err));
  }
};

export const createProspectPremise = payload => async (dispatch, _getState, { api }) => {
  dispatch(fetchRequest());
  try {
    const { data } = await api.prospect.createProspectPremise(payload);
    return dispatch(createProspectWithPremise({ prospectId: payload.prospectId, ...data }));
  } catch (err) {
    toast.error(err);
    return dispatch(error(err));
  }
};

export const selectMeters = meterIds => dispatch => {
  return dispatch(selectMetersForActivation(meterIds));
};

export const fetchOffers = referralCode => async (dispatch, getState, { api }) => {
  dispatch(fetchRequest());

  const prospectId = selectAccountId(getState());

  try {
    const { data } = await api.prospect.getOffers(prospectId, referralCode);
    return dispatch(getOffers(data));
  } catch (err) {
    return dispatch(error(err));
  }
};

export const fetchLegalTemplate = subscriptionTemplateId => async (dispatch, _getState, { api }) => {
  dispatch(fetchRequest());
  try {
    const { data } = await api.prospect.getLegal(subscriptionTemplateId);
    return dispatch(getLegalTemplate(data));
  } catch (err) {
    toast.error(err);
    return dispatch(error(err));
  }
};

export const signOffer = claimTicket => async (dispatch, _getState, { api }) => {
  dispatch(fetchRequest());
  try {
    const { data } = await api.prospect.signOffer(claimTicket);
    await dispatch(renewSession());

    return dispatch(sign(data));
  } catch (err) {
    toast.error(err);
    return dispatch(error(err));
  }
};

export const fetchClaimTicket = (prospectId, subscriptionTemplateId) => async (dispatch, _getState, { api }) => {
  dispatch(fetchClaimTicketRequest());
  try {
    const { data } = await api.prospect.fetchClaimTicket(prospectId, subscriptionTemplateId);
    return dispatch(fetchClaimTicketResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(error(err));
  }
};

export const invalidateContract = () => async dispatch => {
  dispatch(invalidateContractRequest());
};

export const validateContract = claimTicket => async (dispatch, _getState, { api }) => {
  dispatch(validateContractRequest());
  try {
    const { data } = await api.prospect.validateContract(claimTicket);
    return dispatch(validateContractResponse(data));
  } catch (err) {
    return dispatch(validateContractResponse(err));
  }
};

export const fetchContractDetails = (claimTicket, history = null) => async (dispatch, _getState, { api }) => {
  dispatch(fetchContractDetailsRequest());
  try {
    const { data } = await api.prospect.fetchContractDetails(claimTicket);
    return dispatch(fetchContractDetailsResponse(data));
  } catch (err) {
    toast.error(err);
    if (err.response && err.response.status === 401) {
      await dispatch(fetchContractDetailsResponse(err));
      if (history) return history.replace('/logout');
      await dispatch(logout());
    }
    return dispatch(fetchContractDetailsResponse(err));
  }
};

export const fetchContractDetailsWithProspectId = prospectId => async (dispatch, _getState, { api }) => {
  dispatch(fetchContractDetailsWithProspectIdRequest());

  try {
    const { data } = await api.prospect.fetchContractDetailsWithProspectId(prospectId);
    return dispatch(fetchContractDetailsWithProspectIdResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchContractDetailsWithProspectIdResponse(err));
  }
};

export const fetchContract = claimTicket => async (dispatch, _getState, { api }) => {
  dispatch(fetchContractRequest());
  try {
    const { data } = await api.prospect.fetchContract(claimTicket);
    return dispatch(fetchContractResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchContractResponse(err));
  }
};

export const fetchCreditCheckAction = subscriptionTemplateId => async (dispatch, _getState, { api }) => {
  dispatch(fetchCreditCheckActionRequest());
  try {
    const { data } = await api.prospect.fetchCreditCheckAction(subscriptionTemplateId);
    return dispatch(fetchCreditCheckActionResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchCreditCheckActionResponse(err));
  }
};

export const recordOfferSelection = offerId => async (_dispatch, _getState, { api }) => {
  try {
    await api.prospect.recordOfferSelection(offerId);
  } catch (err) {}
};

export const fetchProspectDocuments = () => async (dispatch, _getState, { api }) => {
  dispatch(fetchProspectDocumentsRequest());

  try {
    const { data } = await api.prospect.fetchProspectDocuments();
    return dispatch(fetchProspectDocumentsResponse(data));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchProspectDocumentsResponse(err));
  }
};

export const uploadProspectDocument = formData => async (dispatch, _, { api }) => {
  dispatch(uploadProspectDocumentRequest());

  try {
    const { data } = await api.prospect.uploadProspectDocument(formData);

    if (data.success) {
      return dispatch(uploadProspectDocumentResponse(data));
    }
  } catch (err) {
    toast.error(err);
    return dispatch(uploadProspectDocumentResponse(err));
  }
};

export const checkReferralCode = id => async (dispatch, _, { api }) => {
  dispatch(checkReferralCodeRequest());

  try {
    const response = await api.prospect.checkReferralCode(id);
    return dispatch(checkReferralCodeResponse(response));
  } catch (err) {
    toast.error(err);
    return dispatch(checkReferralCodeResponse(err));
  }
};

export const getCodeDetails = id => async (dispatch, _, { api }) => {
  dispatch(fetchCodeDetailsRequest());

  try {
    const response = await api.prospect.getCodeDetails(id);
    return dispatch(fetchCodeDetailsResponse(response));
  } catch (err) {
    toast.error(err);
    return dispatch(fetchCodeDetailsResponse(err));
  }
};
