import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { getProviderName } from 'shared/store/modules/provider/selectors';
import { selectThemeImage, selectThemeLogo } from 'shared/store/modules/theme/selectors';

import { Layout, AuthContainer, BannerContainer, ErrorContainer } from './AuthLayout.styles';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Alert } from 'reactstrap';
import { selectIsStreamlinedSignUpFlowEnabled } from 'shared/store/modules/features/selectors';

type AuthLayoutProps = PropsWithChildren<{
  title: string;
  error?: any;
  showLoginMesage?: boolean;
}>;

export function AuthLayout(props: AuthLayoutProps) {
  const { children, title, showLoginMesage, error } = props;

  const image = useSelector(state => selectThemeImage(state));
  const logo = useSelector(state => selectThemeLogo(state));
  const name = useSelector(state => getProviderName(state));
  const signupFlowEnabled = useSelector(selectIsStreamlinedSignUpFlowEnabled);

  return (
    <Layout image={image}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <ToastContainer />

      {showLoginMesage && signupFlowEnabled && (
        <BannerContainer>
          <div><FontAwesomeIcon icon={faInfoCircle} className="mr-2 icon" /></div>
          <p>We've made some updates for a more secure login and added user-friendly login options. As part of this update, you'll need to reset your password before logging in the first time. If you signed in with Google previously, continue to sign in with Google. Click "Continue to Login" below to start!</p>
        </BannerContainer>
      )}

      {showLoginMesage && !signupFlowEnabled && (
        <BannerContainer>
          <div><FontAwesomeIcon icon={faInfoCircle} className="mr-2 icon" /></div>
          <p>We've made some updates for a more secure login. As part of this update, you'll need to reset your password before logging in the first time. Click "Continue to Login" below to start!</p>
        </BannerContainer>
      )}

      {error && (
        <ErrorContainer>
          <Alert style={{ width: '500px' }} color="danger">There was an issue with your login. Please try a different method.</Alert>
        </ErrorContainer>
      )}

      <AuthContainer>
        <Link className="d-block" to="/">
          <img alt={`${name} Logo`} className="d-block ml-auto mr-auto mb-5" src={logo} title={name} width="50%" />
        </Link>

        {children}
      </AuthContainer>
    </Layout>
  );
}
